@import '@fontsource-variable/inter';
@import '@fontsource/bungee';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply scroll-smooth font-sans antialiased;
}

html,
body {
  @apply h-full;
}

/* Disable daisyui animation on mount */
.btn {
  @media (prefers-reduced-motion: no-preference) {
    animation: none;
  }
}
